import React from "react";
// import { DialogContentText } from "@material-ui/core/";
// import Data from "../../assets/image/data.pdf";

export default class HabeusData extends React.Component {
  render() {
    return (
      <div
        id="alert-dialog-slide-description"
        color="textPrimary"
        align="justify"
      >
        <h2>TÉRMINOS Y CONDICIONES</h2>

        <a
          href="https://linkmys.com/wp-content/uploads/2022/04/Politica-de-Proteccion-y-Tratamiento-de-Datos-Personales.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terminos, condiciones y politica de proteccion y tratamiento de datos
          personales
        </a>
      </div>
    );
  }
}
