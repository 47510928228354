import React from "react";
import styles from "./styles";
import { UserContext } from "../../context/users";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import HabeusData from "./HabeusData";
import { startRecaptcha } from "../../services/firebase";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import esLocale from "date-fns/locale/es";

// MATERIAL-UI
import {
  Button,
  TextField,
  Link,
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  // Select,
  // FormControl,
  // InputLabel,
  // FormHelperText,
  // Radio,
  // RadioGroup,
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";

// FORMIK
import { Formik } from "formik";
import * as yup from "yup";
import { setLocale } from "yup";
import "./mystyle.css";

// RECURSOS
import Logo from "../../assets/image/Logo.png";
import LinkMarketing from "../../assets/image/LinkMarketing.svg";
// import LogosApoyo from "../../assets/image/logos_que_apoyan.png";
import Campana from "../../assets/image/IMAGENDEINICIO.png";

setLocale({
  mixed: {
    required: "Este campo es obligatorio",
    notType: function notType(_ref) {
      switch (_ref.type) {
        case "number":
          return "Solo se aceptan números";
        case "string":
          return "Solo se aceptan caracteres alfanumericos.";
        default:
          return "Error";
      }
    },
  },
  string: {
    email: "Escriba un correo electrónico válido",
    min: "Este campo debe tener al menos ${min} caracteres",
  },
  number: {
    min: "Este campo debe tener al menos ${min} caracteres",
  },
});

const formSchema = yup.object().shape({
  // phone: yup
  //   .string()
  //   .required()
  //   .matches(
  //     /^[0-9]{10}$/,
  //     "Lo Sentimos, Por favor revisar tu número de teléfono."
  //   ),
  firstName: yup.string().min(3).required(),
  surName: yup.string().min(3).required(),
  code: yup.string().required(),
  cc: yup.string().required(),
  // ally: yup.string().min(3).required(),
  codeInvited: yup.number(),
  termsAndConditions: yup
    .boolean()
    .oneOf(
      [true],
      "Debe aceptar la politica de tratamiendo de datos y los términos y condiciones "
    ),
  // city: yup.string().min(3).required("Nombre de la ciudad es obligatorio"),
  // address: yup.string().min(3).required("La dirección es obligatoria"),
  // code: yup.string().required("Número de documento obligatorio"),
  // emailForm: yup
  //   .string()
  //   .email("El correo electronico es invalido")
  //   .required("El correo electrónico es obligatorio"),
});

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      openDialog: false,
      accessDenied: "",
      loading: false,
      captcha: null,
      // rank: "",
      // sons: "",
      // ally: "",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    startRecaptcha((result) => {
      if (result) {
        this.setState({ captcha: true });
      } else {
        this.setState({
          captcha: false,
          accessDenied: "Demuestra que no eres un robot.",
        });
      }
    });
  }

  onSubmit = async (values, { setSubmitting }) => {
    values.code = values.code.trim();
    if (values.typeOfUser === "b") {
      values.codeInvited = values.codeInvited.trim();
    }
    values.documentNumber = values.code.trim();
    this.setState({
      loading: true,
    });

    if (!this.state.captcha) {
      this.setState({
        accessDenied: "Haz olvidado demostrar que no eres un robot.",
        loading: false,
      });
      return;
    }

    if (values.typeOfUser === "b" && values.code === values.codeInvited) {
      this.setState({
        accessDenied:
          "Si eres un familiar, tú número de documento y del empleado deben ser distintos.",
        loading: false,
      });
      return;
    }

    // if (values.sons !== "NO TENGO") {
    //   if (!values.rank) {
    //     this.setState({
    //       accessDenied:
    //         "Haz olvidado seleccionar que rango de edad tienen tus hijos.",
    //       loading: false,
    //     });
    //     return;
    //   }
    // }

    if (values.typeOfUser === "a") {
      values.email = `${values.code}@bingo.com`;
      values.password = `a123*${values.code}`;
      values.invitedId = values.code;
    } else {
      values.email = `${values.code}@bingo.com`;
      values.password = `a123*${values.code}`;
      values.invitedId = values.codeInvited;
    }

    await this.context.verifyId(values, async (verifyId) => {
      if (verifyId === true) {
        this.context.login(values, (result) => {
          if (result) {
            this.setState({
              loading: true,
            });
          } else {
            this.setState({
              loading: false,
              accessDenied:
                "Error 001. Por favor ponte en contacto con soporte tecnico.",
            });
          }
        });
      } else {
        await this.context.invitedId(values.invitedId, async (result) => {
          if (result.call === 0) {
            this.setState({
              loading: false,
              accessDenied: "No. accesso invalido.",
            });
          } else if (result.call === 1) {
            values.dateAndTime = result.dateAndTime;
            values.guestName = result.guestName;
            values.transmision = result.transmision;
            values.numberOfCartons = result.numberOfCartons;
            this.context.createUser(values, (result) => {
              if (result) {
              } else {
                this.setState({
                  loading: false,
                  accessDenied:
                    "Error 002, por favor ponte en contacto con soporte tecnico.",
                });
              }
            });
          } else {
            this.setState({
              accessDenied: "Este empleado no puede aceptar mas familiares.",
            });
          }
        });
      }
    });
  };

  Copyright() {
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{ fontSize: "8px" }}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://linkmys.com/">
          LINK MARKETING
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  termsAndConditions() {
    return (
      <Typography variant="h6" color="textPrimary">
        {"He leído y acepto la "}
        {/* <Link
          onClick={() =>
            window.open(
              "https://gascaribe.com/politica-de-tratamiento-de-la-informacion-y-datos-personales/",
              "_blank"
            )
          }
          style={{ cursor: "pointer" }}
        >
          Política de privacidad.
        </Link> */}
        {
          <Link onClick={() => this.handleOpen()} style={{ cursor: "pointer" }}>
            Política de tratamiento de datos y los terminos y condiciones.
          </Link>
        }
      </Typography>
    );
  }

  handleClose() {
    this.setState({
      openDialog: false,
    });
  }

  handleOpen() {
    this.setState({
      openDialog: true,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container component="main" className={classes.root}>
          <Grid item xs={false} md={7}>
            <img src={Campana} alt="Inicio" className={classes.image} />
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              justifyContent="center"
              mb={5}
              mt={5}
              className={classes.logoss}
            >
              <img src={Logo} alt="Logo" width="30%" height="100%" />
            </Box>
            <Formik
              initialValues={{
                code: "",
                cc: "",
                firstName: "",
                surName: "",
                typeOfUser: "a",
                termsAndConditions: false,
                codeInvited: "",
                city: "",
                phone: "",
                // emailForm: "",
                // address: "",
                // cc: "",
                // selectedDate: new Date(),
              }}
              validationSchema={formSchema}
              onSubmit={this.onSubmit}
            >
              {(props) => {
                const {
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  isValid,
                  isSubmitting,
                  errors,
                  touched,
                  // setFieldValue,
                } = props;
                return (
                  <div className={classes.paper}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="firstName"
                      label="Nombres:"
                      name="firstName"
                      autoComplete="firstName"
                      value={values.firstName}
                      onChange={handleChange("firstName")}
                      onBlur={handleBlur("firstName")}
                      error={
                        errors.firstName === undefined ||
                        touched.firstName === undefined
                          ? false
                          : true
                      }
                      helperText={
                        errors.firstName && touched.firstName
                          ? errors.firstName
                          : null
                      }
                      size="small"
                      className={classes.textField}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="surName"
                      label="Apellidos:"
                      name="surName"
                      autoComplete="surName"
                      value={values.surName}
                      onChange={handleChange("surName")}
                      onBlur={handleBlur("surName")}
                      error={
                        errors.surName === undefined ||
                        touched.surName === undefined
                          ? false
                          : true
                      }
                      helperText={
                        errors.surName && touched.surName
                          ? errors.surName
                          : null
                      }
                      size="small"
                      className={classes.textField}
                    />
                    {/* <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Fecha de nacimiento."
                        format="dd/MM/yyyy"
                        value={values.selectedDate}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setFieldValue("selectedDate", date)}
                        maxDate={new Date()}
                        size="small"
                        fullWidth
                        className={classes.textField}
                      />
                    </MuiPickersUtilsProvider> */}

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="cc"
                      label="Cédula:"
                      name="cc"
                      autoComplete="cc"
                      value={values.cc}
                      onChange={handleChange("cc")}
                      onBlur={handleBlur("cc")}
                      error={
                        errors.cc === undefined || touched.cc === undefined
                          ? false
                          : true
                      }
                      helperText={errors.cc && touched.cc ? errors.cc : null}
                      size="small"
                      className={classes.textField}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="code"
                      label="Código"
                      name="code"
                      autoComplete="code"
                      value={values.code}
                      onChange={handleChange("code")}
                      onBlur={handleBlur("code")}
                      error={
                        errors.code === undefined || touched.code === undefined
                          ? false
                          : true
                      }
                      helperText={
                        errors.code && touched.code ? errors.code : null
                      }
                      size="small"
                      className={classes.textField}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="phone"
                      label="Celular:"
                      name="phone"
                      autoComplete="phone"
                      value={values.phone}
                      onChange={handleChange("phone")}
                      onBlur={handleBlur("phone")}
                      error={
                        errors.phone === undefined ||
                        touched.phone === undefined
                          ? false
                          : true
                      }
                      helperText={
                        errors.phone && touched.phone ? errors.phone : null
                      }
                      size="small"
                      className={classes.textField}
                    />

                    {/*<TextField
                      type="mail"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="emailForm"
                      label="Correo:"
                      name="emailForm"
                      autoComplete="emailForm"
                      value={values.emailForm}
                      onChange={handleChange("emailForm")}
                      onBlur={handleBlur("emailForm")}
                      error={
                        errors.emailForm === undefined ||
                        touched.emailForm === undefined
                          ? false
                          : true
                      }
                      helperText={
                        errors.emailForm && touched.emailForm
                          ? errors.emailForm
                          : null
                      }
                      size="small"
                      className={classes.textField}
                    />*/}

                    {/* <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="address"
                      label="Dirección:"
                      name="address"
                      autoComplete="address"
                      value={values.address}
                      onChange={handleChange("address")}
                      onBlur={handleBlur("address")}
                      error={
                        errors.address === undefined ||
                        touched.address === undefined
                          ? false
                          : true
                      }
                      helperText={
                        errors.address && touched.address
                          ? errors.address
                          : null
                      }
                      size="small"
                      className={classes.textField}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="city"
                      label="Ciudad:"
                      name="city"
                      autoComplete="city"
                      value={values.city}
                      onChange={handleChange("city")}
                      onBlur={handleBlur("city")}
                      error={
                        errors.city === undefined || touched.city === undefined
                          ? false
                          : true
                      }
                      helperText={
                        errors.city && touched.city ? errors.city : null
                      }
                      size="small"
                      className={classes.textField}
                    /> */}

                    {/* <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="ally"
                      label="Aliado al que pertenece"
                      name="ally"
                      autoComplete="ally"
                      value={values.ally}
                      onChange={handleChange("ally")}
                      onBlur={handleBlur("ally")}
                      error={
                        errors.ally === undefined || touched.ally === undefined
                          ? false
                          : true
                      }
                      helperText={
                        errors.ally && touched.ally ? errors.ally : null
                      }
                      size="small"
                      className={classes.textField}
                    /> */}
                    {/* <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      error={
                        errors.sons === undefined || touched.sons === undefined
                          ? false
                          : true
                      }
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        ¿Cuantos hijos tiene?
                      </InputLabel>
                      <Select
                        native
                        value={values.sons}
                        onChange={(e) => {
                          setFieldValue("sons", e.target.value);
                          setFieldValue("rank", "");
                        }}
                        onBlur={handleBlur("sons")}
                        label="¿Cuantos hijos tiene?"
                      >
                        <option aria-label="None" value="" />
                        <option aria-label="1" value="1">
                          1
                        </option>
                        <option aria-label="2" value="2">
                          2
                        </option>
                        <option aria-label="3" value="3">
                          3
                        </option>
                        <option aria-label="+4" value="+4">
                          +4
                        </option>
                        <option aria-label="NO TENGO" value="NO TENGO">
                          NO TENGO
                        </option>
                      </Select>
                      {errors.sons && touched.sons ? (
                        <FormHelperText>{errors.sons}</FormHelperText>
                      ) : null}
                    </FormControl> */}
                    {/* <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      error={
                        errors.rank === undefined || touched.rank === undefined
                          ? false
                          : true
                      }
                      disabled={
                        !values.sons
                          ? true
                          : values.sons === "NO TENGO"
                          ? true
                          : false
                      }
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Que rango de edad tienen tus hijos
                      </InputLabel>
                      <Select
                        native
                        value={values.rank}
                        onChange={handleChange("rank")}
                        onBlur={handleBlur("rank")}
                        label="Que rango de edad tienen tus hijos"
                      >
                        <option aria-label="None" value="" />
                        <option
                          aria-label="De 1 a 5 años"
                          value="De 1 a 5 años"
                        >
                          De 1 a 5 años
                        </option>
                        <option
                          aria-label="De 5 a 10 años"
                          value="De 5 a 10 años"
                        >
                          De 5 a 10 años
                        </option>
                        <option
                          aria-label="De 10 a 15 años"
                          value="De 10 a 15 años"
                        >
                          De 10 a 15 años
                        </option>
                        <option
                          aria-label="De 15 a 20 años"
                          value="De 15 a 20 años"
                        >
                          De 15 a 20 años
                        </option>
                        <option
                          aria-label="Mayores de 20 años"
                          value="Mayores de 20 años"
                        >
                          Mayores de 20 años
                        </option>
                      </Select>
                      {errors.rank && touched.rank ? (
                        <FormHelperText>{errors.rank}</FormHelperText>
                      ) : null}
                    </FormControl> */}
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ width: "96%" }}
                    >
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.termsAndConditions}
                              id="termsAndConditions"
                              name="termsAndConditions"
                              onChange={handleChange("termsAndConditions")}
                              onBlur={handleBlur("termsAndConditions")}
                              style={{
                                color: "#000",
                              }}
                            />
                          }
                        />
                      </Box>
                      <Box style={{ marginLeft: "-18px" }}>
                        {this.termsAndConditions()}
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" mb={1}>
                      <div id="recaptcha"></div>
                    </Box>
                    <Box display="flex" justifyContent="center" mb={1}>
                      {this.state.accessDenied !== "" ? (
                        <Alert severity="error">
                          {this.state.accessDenied}
                        </Alert>
                      ) : null}
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={
                          isSubmitting || !isValid || this.state.loading
                        }
                        onClick={handleSubmit}
                      >
                        {this.state.loading ? (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        ) : (
                          "GUARDAR"
                        )}
                      </Button>
                    </Box>
                  </div>
                );
              }}
            </Formik>

            {/* <Box
              display="flex"
              justifyContent="center"
              className={classes.marcas}
            >
              <img
                src={LogosApoyo}
                alt="LogosApoyo"
                width="50%"
                height="100%"
              />
            </Box> */}

            <Box
              display="flex"
              justifyContent="center"
              style={{ margin: "80px 16px 16px 16px" }}
            >
              <img
                src={LinkMarketing}
                alt="LinkMarketing"
                width="10%"
                height="100%"
              />
            </Box>

            <Box mt={2}>{this.Copyright()}</Box>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.openDialog}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <HabeusData />
          </DialogContent>
        </Dialog>
        <div
          className={classes.flotante}
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=573009109963",
              "_blank"
            );
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <div className={classes.pack}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <span>
                  <WhatsAppIcon className={classes.icon} />
                </span>
                <span className={classes.textSupport}>Soporte</span>
              </Box>
            </div>
          </Box>
        </div>
      </React.Fragment>
    );
  }
}

Login.contextType = UserContext;

export default withStyles(styles)(Login);
