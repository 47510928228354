import React from "react";
import styles from "./styles";
import PropTypes from "prop-types";

//COMPONENTES
import AlertDialog from "../../components/alertDialog";
import ChromeTabs from "./ChromeTabs";
import Card from "../Card";
// MATERIAL UI
import { Box, Typography } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class TabsViews extends React.Component {
  state = {
    tabIndex: 0,
    numberCard: 0,
    openDialog: false,
    message: "¿Estas seguro que quieres cantar bingo?",
  };

  handleChange = (event, newValue) => {
    this.setState({
      tabIndex: newValue,
    });
  };

  handleChangeIndex = (index) => {
    this.setState({
      tabIndex: index,
    });
  };

  updateUser = (user, index) => {
    user.winners[index] = 1;
    this.props.updateUser((result) => {
      console.log(result);
    }, user.winners);
  };

  handleOpen = (user, selected, numberCard) => {
    this.setState({
      openDialog: true,
      user: user,
      selected: selected,
      numberCard: numberCard,
    });
  };

  handleClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleYesDialog = () => {
    const { user, numberCard } = this.state;
    let userData = user;
    const attempts = user.attempts + 1;
    let winners = 2;
    if (attempts >= 3) {
      winners = 6;
    }
    if (user.winners[numberCard] !== 6) {
      userData.winners[numberCard] = winners;
      userData.attempts = attempts;

      const bingoSingData = {
        selected: this.state.selected,
        documentNumber: user.documentNumber,
        firstName: user.firstName,
        surName: user.surName,
        bingo: user.bingo[numberCard],
        winners: user.winners,
        phone: user.phone,
        city: user.city,
        id: user.code,
        invitedId: user.invitedId,
        invitedName: user.guestName,
        numberCard: numberCard,
      };

      this.props.bingoSing(
        (result) => {
          console.log(result);
          this.setState({
            openDialog: false,
          });
        },
        bingoSingData,
        userData
      );
    }
  };

  render() {
    const { classes, user, transmision } = this.props;
    const { tabIndex } = this.state;

    return (
      <React.Fragment>
        <Box display="flex" justifyContent="center" mb={1}>
          <Box p={1} className={classes.sol}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              HOLA
              <br></br>
              {/* {`${user.guestName.toUpperCase()}`} */}
              {`${user.firstName.toUpperCase()} ${user.surName.toUpperCase()}`}
            </Typography>
            <ChromeTabs
              bingo={user.bingo}
              handleChange={this.handleChange}
              tabIndex={tabIndex}
            />
            <SwipeableViews
              axis={"x-reverse"}
              index={tabIndex}
              onChangeIndex={this.handleChangeIndex}
              className={classes.swipeableViews}
              // style={{ border: "1px solid #000" }}
            >
              {user.bingo.map((value, index) => {
                return (
                  <TabPanel value={tabIndex} index={index} key={index}>
                    <Card
                      user={user}
                      handleOpen={this.handleOpen}
                      updateUser={this.updateUser}
                      transmision={transmision}
                      index={index}
                    />
                  </TabPanel>
                );
              })}
            </SwipeableViews>
          </Box>
        </Box>
        <AlertDialog
          title="¿Quieres cantar BINGO?"
          message={this.state.message}
          handleClose={this.handleClose}
          handleYesDialog={this.handleYesDialog}
          openDialog={this.state.openDialog}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TabsViews);
